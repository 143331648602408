import React, {useState, useEffect} from "react"
import {getInmueblesCond, getCondominioById} from "@/api/condominio"
import {useNavigate} from "react-router-dom"
import ImagesColumn from "@/components/carrousel/ImagesColumn"
import dayjs from "dayjs"
import EditRoundedIcon from "@mui/icons-material/EditRounded"
import CameraAltIcon from "@mui/icons-material/CameraAlt"
import InmuebleCard from "@/components/properties/PropertyCard"
import {Condo} from "@/types/Condo"
import EditButton from "../properties/EditButton"
import PageSection from "@/layouts/PageSection"
import {CONDO_SECTIONS} from "@/constants"
import {useAuthContext} from "@/hooks/useAuthContext"

interface Props {
   condId: string
   condoData?: Condo
   shouldNavigate?: boolean
   styles: {
      disponibilidadBtn: string
      gridDivs: string
      h5Titles: string
      iconColors: string
      caracteristicas: string
   }
}

function CondominioInfo({condId, condoData, shouldNavigate = false}: Props) {
   const navigate = useNavigate()
   // state
   const [condominio, setCondominio] = useState<null | any>(null)
   const [inmuebles, setInmuebles] = useState<any>(null)
   const {user} = useAuthContext()

   useEffect(() => {
      // get condo properties
      const getCondoProperties = async () => {
         const inmueblesRes = await getInmueblesCond(condId)
         setInmuebles(inmueblesRes.data.data)
      }
      // get the values from that condominio
      const fetchCondominio = async () => {
         const res = await getCondominioById(condId)
         setCondominio(res.data.data)
         getCondoProperties()
      }
      if (!condoData) {
         fetchCondominio()
         return
      }
      setCondominio(condoData)
      getCondoProperties()
   }, [])

   return (
      <>
         {condominio && (
            <div className="mb-10 flex flex-col" id="condo-info">
               {user.admin && (
                  <section className="flex gap-2 self-end">
                     <EditButton
                        label="Editar Condominio"
                        onClick={() =>
                           navigate(`/condominios/editCondominio/${condId}`, {
                              state: {condominio},
                           })
                        }
                        icon={<EditRoundedIcon fontSize="small" />}
                     />
                     <EditButton
                        label="Editar Fotos"
                        onClick={() =>
                           navigate(`/condominios/editPhotos/${condId}`, {
                              state: {condominio},
                           })
                        }
                        icon={<CameraAltIcon fontSize="small" />}
                     />
                  </section>
               )}
               {condominio.createdAt && (
                  <p className="my-5 text-center">
                     Creado el: {dayjs.unix(condominio.createdAt?._seconds).format("DD-MM-YYYY")}
                  </p>
               )}
               <section className="grid grid-cols-2 md:flex md:flex-wrap gap-4 items-center justify-center mt-4">
                  {CONDO_SECTIONS.map((section, i) => (
                     <button
                        key={i}
                        className="bg-blue rounded text-white px-4 py-1"
                        onClick={() => {
                           const element = document.getElementById(section.id)
                           element?.scrollIntoView({behavior: "smooth"})
                        }}
                     >
                        {section.title}
                     </button>
                  ))}
               </section>
               <header className="flex flex-col md:flex-row items-center justify-center mb-8 md:min-h-[400px] py-2 md:py-8">
                  <img
                     src={condominio.mainImageUrl}
                     alt="condominio"
                     className="md:w-[50%] h-auto w-full object-cover rounded-lg mx-auto my-5"
                  />
                  <div className="md:w-[50%] w-full md:ml-4 flex flex-col gap-2">
                     <h2 className="font-bold text-blue">Condominio</h2>
                     <h1
                        className={
                           shouldNavigate ? "font-bold underline cursor-pointer" : "font-bold"
                        }
                        onClick={() => (shouldNavigate ? navigate("/condominios/" + condId) : null)}
                     >
                        {condominio.name}
                     </h1>
                     <p className="font-bold text-green text-sm">
                        {condominio.class ? "Clasificación: " + condominio.class : "Sin clasificar"}
                     </p>
                     {condominio.amenities && (
                        <>
                           <h4>Amenidades</h4>
                           <div className="flex flex-wrap text-sm gap-2">
                              {condominio.amenities?.map((amenidad: any) => (
                                 <div
                                    key={amenidad}
                                    className="border rounded border-blue text-blue p-1"
                                 >
                                    {amenidad}
                                 </div>
                              ))}
                           </div>
                        </>
                     )}
                     <div>
                        <h4>Zona</h4>
                        <p>{condominio.zone}</p>
                     </div>
                     <div>
                        <h4>Ubicación</h4>
                        {condominio.mapsShareUrl && (
                           <a
                              href={condominio.mapsShareUrl}
                              target="_blank"
                              rel="noreferrer"
                              className="underline text-blue"
                           >
                              {condominio.mapsShareUrl}
                           </a>
                        )}
                        <p>Dirección: {condominio.address}</p>
                     </div>
                  </div>
               </header>
               <PageSection
                  id="condo-description"
                  title="Información"
                  className="flex flex-col gap-2"
               >
                  <div>
                     <h3 className="text-blue">Descripción del condominio</h3>
                     <p>{condominio.desc}</p>
                  </div>
                  <div>
                     <h3>Más información</h3>
                     <p>Acceso a la playa: {condominio.access_to_beach}</p>
                     <p>Antigüedad: {condominio.antiquity}</p>
                     <p>Club de playa: {condominio.beach_club ? "Sí" : "No"}</p>
                     <p>Pet friendly: {condominio.pet_friendly ? "Sí" : "No"}</p>
                     <p>
                        Rentas vacacionales permitidas:
                        {condominio.vacation_rentals_allowed ? "Sí" : "No"}
                     </p>
                  </div>
               </PageSection>
               <PageSection id="condo-files" title="Archivos">
                  <p>(pendiente)</p>
               </PageSection>
               <PageSection
                  id="condo-properties"
                  title={`Inmuebles en ${condominio.name}`}
                  className="p-0"
               >
                  {inmuebles && (
                     <div className="mb-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 items-center gap-4 justify-center pr-2">
                        {inmuebles.map((inmueble: any) => (
                           <InmuebleCard key={inmueble.id} inmueble={inmueble} />
                        ))}
                     </div>
                  )}
               </PageSection>
               <PageSection id="condo-images" title="Imágenes">
                  {condominio.images && <ImagesColumn id={condId} source="condo" />}
               </PageSection>
               <PageSection id="condo-videos" title="Videos">
                  {condominio.videoId && (
                     <iframe
                        src={`https://www.youtube.com/embed/${condominio.videoId}`}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        className="my-7 mx-auto w-[300px] h-[200px] md:w-[700px] md:h-[450px]"
                        allowFullScreen
                     />
                  )}
               </PageSection>
               <PageSection
                  id="condo-location"
                  title="Ubicación"
                  className="flex flex-col items-center text-center mx-4 md:mx-[20%] max-w-full"
               >
                  {condominio.googleMaps && (
                     <iframe
                        src={condominio.googleMaps}
                        className="my-6 h-[200px] w-[300px] md:h-[450px] md:w-[700px] mx-auto"
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                     />
                  )}
                  {condominio.mapsShareUrl && (
                     <>
                        <p className="font-bold">Ver Ubicación</p>
                        <a
                           href={condominio.mapsShareUrl}
                           target="_blank"
                           rel="noreferrer"
                           className="text-blue underline truncate"
                        >
                           {condominio.mapsShareUrl}
                        </a>
                     </>
                  )}
                  {condominio.address && (
                     <p className="my-4 text-sm md:mx-[20%]">Dirección: {condominio.address}</p>
                  )}
               </PageSection>
            </div>
         )}
      </>
   )
}

export default CondominioInfo

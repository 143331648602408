import React from "react"
import {useNavigate} from "react-router-dom"
import {Condo} from "../../types/Condo"

function CondominioCard({condo}: {condo: Condo}) {
   const navigate = useNavigate()

   const toSingleCondo = () => {
      navigate(`/condominios/${condo.id}`, {state: {condo}})
   }

   return (
      <button
         className="flex flex-col md:flex-row md:max-w-[1400px] h-[600px] md:h-[300px] shadow-md border rounded-lg items-center hover:shadow-lg p-2 gap-4 w-full"
         onClick={toSingleCondo}
      >
         <img
            src={condo.mainImageUrl}
            className="rounded-lg w-full md:w-[30%] h-[250px] md:h-full object-cover"
            alt="Imagen principal del condominio"
         />
         <section className="flex flex-col items-start text-start w-full md:w-[35%] gap-2 px-4 md:px-0">
            <h3 className="text-lg font-bold">{condo.name}</h3>
            <p className="text-sm">{condo.address}</p>
            <p className="hidden md:flex overflow-y-auto max-h-[100px]">{condo.desc}</p>
         </section>
         <section className="flex flex-wrap text-sm overflow-y-auto w-full md:w-[30%] gap-2 px-4 md:px-0">
            {condo.amenities?.map((a: string) => (
               <div key={a} className="border rounded border-blue text-blue p-0.5 truncate">
                  {a}
               </div>
            ))}
         </section>
      </button>
   )
}

export default CondominioCard

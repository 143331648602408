/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from "react"
import ShareIcon from "@mui/icons-material/Share"
import {getCondominioById} from "../../api/condominio"
import {REDIRECT_URL} from "../../constants"
import copy from "copy-to-clipboard"
import {getTypeForURL, standarizeName} from "../../utils"
import {PropertyType} from "@/types/Property"

interface SharingProps {
   name: string
   condoId: string
   costs: any
   description: string
   type: PropertyType
   explicitRent: boolean
   zone: string
   capacity: number
   surface: number
   constructionMts: number
   mapsShareUrl: string
   id: string
   videos: string[]
   className?: string
   accessToBeach: string
}

export default function ShareBtn({
   name,
   costs,
   condoId,
   description,
   explicitRent,
   zone,
   capacity,
   surface,
   constructionMts,
   type,
   videos,
   mapsShareUrl,
   className,
   id,
   accessToBeach,
}: SharingProps) {
   const [condo, setCondo] = useState<any>(null)
   useEffect(() => {
      const fetchCondo = async () => {
         if (condoId) {
            const res = await getCondominioById(condoId)
            setCondo(res.data.data)
         } else {
            setCondo(null)
         }
      }
      fetchCondo()
   }, [])

   const parsedRentCosts = {
      nightRegular: costs?.["noche_regular"]?.toLocaleString("en-US", {
         style: "currency",
         currency: "MXN",
         minimumFractionDigits: 0,
         maximumFractionDigits: 0,
      }),
      nightMid: costs?.["noche_media"]?.toLocaleString("en-US", {
         style: "currency",
         currency: "MXN",
         minimumFractionDigits: 0,
         maximumFractionDigits: 0,
      }),
      nightHigh: costs?.["noche_alta"]?.toLocaleString("en-US", {
         style: "currency",
         currency: "MXN",
         minimumFractionDigits: 0,
         maximumFractionDigits: 0,
      }),
      monthlyRegular: costs?.["mensual_regular"]?.toLocaleString("en-US", {
         style: "currency",
         currency: "MXN",
         minimumFractionDigits: 0,
         maximumFractionDigits: 0,
      }),
      monthlyAnnual: costs?.["mensual_anual"]?.toLocaleString("en-US", {
         style: "currency",
         currency: "MXN",
         minimumFractionDigits: 0,
         maximumFractionDigits: 0,
      }),
   }
   const parsedSaleCosts = {
      pricePerMeter: costs?.pricePerMeter?.toLocaleString("en-US", {
         style: "currency",
         currency: "MXN",
         minimumFractionDigits: 0,
         maximumFractionDigits: 0,
      }),
      salePrice: costs?.salePrice?.toLocaleString("en-US", {
         style: "currency",
         currency: "MXN",
         minimumFractionDigits: 0,
         maximumFractionDigits: 0,
      }),
      maintenance: costs?.maintenance?.toLocaleString("en-US", {
         style: "currency",
         currency: "MXN",
         minimumFractionDigits: 0,
         maximumFractionDigits: 0,
      }),
   }

   const isSale = parsedSaleCosts.salePrice !== undefined

   const mapsUrl = mapsShareUrl || condo?.mapsShareUrl
   const youtubeUrl = videos?.find((v: string) => v.includes("youtube"))
   let rentText = ""
   let saleText = ""
   const forRent = explicitRent || !isSale
   const urlPath = `/${forRent ? "renta" : "venta"}/${getTypeForURL(type)}/${standarizeName(
      name,
   )}?id=${id}`
   const lastText = `ℹ️ *Ver información de ${name}*\n${REDIRECT_URL}${urlPath}`

   const handleCopy = (e: React.MouseEvent) => {
      e.stopPropagation()
      if (forRent) {
         if (zone) {
            rentText = `🏘️ ${zone}` + "\n\n"
            }
            rentText = rentText + `*${name}*` + "\n"
         if (type){
               rentText = rentText + `🏠 Tipo de propiedad - ${type}\n`
            }
         if (accessToBeach){
               rentText = rentText + `🏖️ Acceso a la playa - ${accessToBeach}\n\n`
            } 
         if (capacity) {
            rentText = rentText + `👨‍👩‍👦‍👦 Capacidad Máxima - ${capacity} personas\n\n`
         }
         if (parsedRentCosts.nightRegular) {
            rentText =
               rentText +
               `🏷️ *Precio de renta por noche temporada regular ${parsedRentCosts.nightRegular}*\n\n`
         }
         if (parsedRentCosts.nightMid) {
            rentText =
               rentText +
               `🏷️ *Precio de renta por noche temporada media ${parsedRentCosts.nightMid}*\n\n`
         }
         if (parsedRentCosts.nightHigh) {
            rentText =
               rentText +
               `🏷️ *Precio de renta por noche temporada alta ${parsedRentCosts.nightHigh}*\n\n`
         }
         if (parsedRentCosts.monthlyRegular) {
            rentText =
               rentText +
               `🏷️ *Renta por mes en temporada regular ${parsedRentCosts.monthlyRegular}*\n\n`
         }
         if (parsedRentCosts.monthlyAnnual) {
            rentText =
               rentText +
               `🏷️ *Renta mensual con contrato anual ${parsedRentCosts.monthlyAnnual}*\n\n`
         }
         if (description) {
            rentText = rentText + `${description}\n\n`
         }
         if (surface && type !== PropertyType.APARTMENT) {
            rentText = rentText + `📏 Superficie de terreno ${surface.toLocaleString()} Mts²\n`
         }
         if (constructionMts) {
            rentText =
               rentText +
               `🏠 Superficie de construcción ${constructionMts.toLocaleString()} Mts²\n\n`
         } else {
            rentText = rentText + "\n"
         }
         rentText = rentText + lastText
         if (youtubeUrl) {
            rentText = rentText + `\n\n🎥 *Ver video de ${name}*\n${youtubeUrl}`
         }
         if (mapsUrl) {
            rentText = rentText + `\n\n📍 *Ver ubicación de ${name}*\n${mapsUrl}`
         }
         copy(rentText)
      } else {
         if (zone) {
            saleText = `🏘️ ${zone}` + "\n\n"
            }
            saleText = saleText + `*${name}*\n`
         if (type){
            saleText = saleText + `🏠 Tipo de propiedad - ${type}\n`
            }
         if (accessToBeach){
               saleText = saleText + `🏖️ Acceso a la playa - ${accessToBeach}\n\n`
            saleText = saleText + `🏷️ *Precio de venta ${parsedSaleCosts.salePrice}*\n\n`
         }
         if (parsedSaleCosts.pricePerMeter) {
            saleText =
               saleText + `🏷️ *Precio por metro cuadrado ${parsedSaleCosts.pricePerMeter}*\n\n`
         }
         if (parsedSaleCosts.maintenance) {
            saleText = saleText + `🏷️ *Mantenimiento mensual ${parsedSaleCosts.maintenance}*\n\n`
         }
         if (description) {
            saleText = saleText + `${description}\n`
         } else {
            saleText = saleText + "\n"
         }
         if (surface && type !== PropertyType.APARTMENT) {
            saleText = saleText + `📏 Superficie de terreno ${surface.toLocaleString()} Mts²\n`
         }
         if (constructionMts) {
            saleText =
               saleText +
               `🏠 Superficie de construcción ${constructionMts.toLocaleString()} Mts²\n\n`
         } else {
            saleText = saleText + "\n"
         }
         saleText = saleText + lastText
         if (youtubeUrl) {
            saleText = saleText + `\n\n🎥 *Ver video de ${name}*\n${youtubeUrl}`
         }
         if (mapsUrl) {
            saleText = saleText + `\n\n📍 *Ver ubicación de ${name}*\n${mapsUrl}`
         }
         copy(saleText)
      }
      alert(`¡Información de ${name} copiada al portapapeles!
Simplemente pega en en tu red social favorita o en donde quieras compartir.`)
   }
   return (
      <button
         className={`drop-shadow-lg fixed bottom-8 right-8 rounded-full p-3 bg-blue-600 z-50 ${className}`}
         onClick={handleCopy}
      >
         <ShareIcon style={{color: "white"}} />
      </button>
   )
}

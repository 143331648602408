import {TextField} from "@mui/material"
import React from "react"
import Calendar from "react-calendar"
import "react-calendar/dist/Calendar.css"
import {Button, Alert} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import AddIcon from "@mui/icons-material/Add"
import {editDate} from "../../api/inmueble"
import InputLabel from "@mui/material/InputLabel"
import NativeSelect from "@mui/material/NativeSelect"
import "./Calendar.css"
import {clearCache} from "@/utils"

interface CalendarFormProps {
   hideForm: () => void
   inmuebleId: string
   fetchInmueble: () => void
   calendarPast: any
   currDate: any
   currNote: string
   apiDate: string
}

type RentedByType = "CAD" | "Propietario"

function EditCalendarForm({
   hideForm,
   inmuebleId,
   fetchInmueble,
   calendarPast,
   currDate,
   currNote,
   apiDate,
}: CalendarFormProps) {
   const [dateRange, setDateRange] = React.useState<any>(currDate)
   const [rentedBy, setRentedBy] = React.useState<RentedByType>("CAD")
   const [notes, setNotes] = React.useState(currNote)
   const [error, setError] = React.useState<string | boolean>(false)

   const isWithinRanges = (date: Date, ranges = []) => {
      return ranges.some((range: any) => {
         const start = new Date(range.start._seconds * 1000)
         const end = new Date(range.end._seconds * 1000)
         return date >= start && date <= end
      })
   }

   const tileDisabled = ({date, view}: any) => {
      if (view === "month") {
         return isWithinRanges(date, calendarPast)
      }
      return false
   }

   const isRangeOverlapping = (start: Date, end: Date, ranges = []) => {
      return ranges.some((range: any) => {
         const rangeStart = new Date(range.start._seconds * 1000)
         const rangeEnd = new Date(range.end._seconds * 1000)
         return (
            (start >= rangeStart && start <= rangeEnd) ||
            (end >= rangeStart && end <= rangeEnd) ||
            (start <= rangeStart && end >= rangeEnd)
         )
      })
   }

   const handleSubmit = async () => {
      try {
         setError(false)
         if (!dateRange) {
            setError("Selecciona un rango de fechas")
            return
         }
         if (dateRange.length < 2) {
            setError("Selecciona un rango de fechas válido")
            return
         }
         const [start, end] = dateRange
         if (isRangeOverlapping(start, end, calendarPast)) {
            setError("El rango seleccionado incluye fechas que ya están reservadas")
            return
         }
         const calendar = {
            start: dateRange[0],
            end: dateRange[1],
            notes,
         }
         await editDate(inmuebleId, calendar, calendarPast, apiDate)
         fetchInmueble()
         hideForm()
         clearCache()
      } catch (error) {
         console.log(error)
         setError("Error al crear la fecha")
      }
   }

   return (
      <div className="rounded shadow-md">
         <h4 className="my-3 font-bold text-lg text-blue-600">Añadir fecha</h4>
         {error && (
            <Alert severity="error" className="w-[27%] mb-4 mx-auto">
               {error}
            </Alert>
         )}
         <Calendar
            onChange={setDateRange}
            value={dateRange}
            selectRange={true}
            className="mx-auto mb-3"
            tileDisabled={tileDisabled}
         />
         <div className="mx-auto w-full md:w-[50%] h-full my-6">
            <InputLabel variant="standard" className="text-start mb-2 ml-4">
               Ocupación Propietario / CAD
            </InputLabel>
            <NativeSelect
               className="w-full md:w-[80%] p-2"
               defaultValue={"CAD"}
               onChange={(e) => setRentedBy(e.target.value as RentedByType)}
               value={rentedBy}
            >
               <option value="CAD">CAD</option>
               <option value="Propietario">Propietario</option>
            </NativeSelect>
         </div>
         <TextField
            label={"Notas"}
            id="margin-none"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            className="w-[27%] mb-4"
         />
         <div className="flex flex-row justify-center space-x-6 flex-wrap my-4">
            <Button
               variant="contained"
               style={{
                  backgroundColor: "#dc2626",
                  color: "white",
                  marginBottom: "10px",
               }}
               startIcon={<DeleteIcon />}
               onClick={hideForm}
            >
               Cancelar
            </Button>
            <Button
               variant="contained"
               style={{
                  backgroundColor: dateRange ? "#2563eb" : "#9ca3af",
                  color: "white",
                  marginBottom: "10px",
               }}
               startIcon={<AddIcon />}
               onClick={
                  dateRange
                     ? () => handleSubmit()
                     : () => {
                          console.log("No date range selected")
                       }
               }
               disabled={!dateRange}
            >
               Agregar
            </Button>
         </div>
      </div>
   )
}

export default EditCalendarForm
